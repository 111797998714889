const BASIC_REST = "";
const POST = "post";
const GET = "get";
const VARIANTS_REST = BASIC_REST + "/VARIANTS";

const REGISTER_NEW_TEST = {
  url: BASIC_REST + "/registerNewTest",
  method: POST,
};

const MODIFY_PRS_LIST_TEST = {
  url: BASIC_REST + "/modifyPRSListTest",
  method: POST,
};

const UPDATE_PATIENT_INFO = {
  url: BASIC_REST + "/updatePatientInfo",
  method: POST,
};

const CREATE_PAYMENT_INTENT = {
  url: BASIC_REST + "/createPaymentIntent",
  method: POST,
};

const REGISTER_NEW_TEST_CSV = {
  url: BASIC_REST + "/registerTestCsv",
  method: POST,
};

const SEARCH_REGISTERED_TEST = {
  url: BASIC_REST + "/searchRegisteredTest",
  method: GET,
};

const SEARCH_REGISTERED_TEST_VARIANTS = {
  url: VARIANTS_REST + "/searchRegisteredTest",
  method: GET,
};

const SEARCH_JOINT_REPORTS = {
  url: BASIC_REST + "/searchJointReports",
  method: GET,
};

const DOWNLOAD_JOINT_REPORT = {
  url: BASIC_REST + "/getJointReport",
  method: GET,
};

const PATIENT_LIST_VIEW = {
  url: BASIC_REST + "/listPatientView",
  method: GET,
};

const UPLOAD_PATIENT_CONSENT = {
  url: BASIC_REST + "/uploadTestPatientConsent",
  method: POST,
};
const GET_PATIENT_REPORT = {
  url: BASIC_REST + "/getTestPatientReport",
  method: GET,
};
const GET_PATIENT_CONSENT = {
  url: BASIC_REST + "/getTestPatientConsent",
  method: GET,
};

const GET_TRACKING_INFO = {
  url: BASIC_REST + "/searchTrackingTest",
  method: GET,
};

const SEARCH_PATIENT_INFO = {
  url: BASIC_REST + "/searchPatientInfo",
  method: GET,
};

const PATIENT_DETAILS = {
  url: BASIC_REST + "/patientDetails",
  method: GET,
};

const GET_USER_INFO = {
  url: BASIC_REST + "/getUserInfo",
  method: GET,
};

const SAVE_USER_CONFIG = {
  url: BASIC_REST + "/saveUserConfig",
  method: POST,
};

const CHANGE_USER_PASSWORD = {
  url: BASIC_REST + "/changePassword",
  method: POST,
};

const ASSIGN_BARCODE_PATIENT = {
  url: BASIC_REST + "/assignBarcode",
  method: POST,
};

const DEALLOCATE_BARCODE_PATIENT = {
  url: BASIC_REST + "/deallocateBarcode",
  method: POST,
};

const DELETE_PATIENT_CONSENT = {
  url: BASIC_REST + "/deleteTestPatientConsent",
  method: POST,
};

const UPLOAD_GENETIC_IDAT = {
  url: BASIC_REST + "/uploadGeneticIdat",
  method: POST,
};

const DELETE_GENETIC_IDAT = {
  url: BASIC_REST + "/deleteGeneticIdat",
  method: POST,
};

const UPLOAD_GENETIC_VCF = {
  url: BASIC_REST + "/uploadGeneticVcf",
  method: POST,
};

const DELETE_GENETIC_VCF = {
  url: BASIC_REST + "/deleteGeneticVcf",
  method: POST,
};

const UPLOAD_GENETIC_CEL = {
  url: BASIC_REST + "/uploadGeneticCel",
  method: POST,
};

const DELETE_GENETIC_CEL = {
  url: BASIC_REST + "/deleteGeneticCel",
  method: POST,
};

const UPLOAD_GENETIC_FASTQ = {
  url: BASIC_REST + "/uploadGeneticFastq",
  method: POST,
};

const DELETE_GENETIC_FASTQ = {
  url: BASIC_REST + "/deleteGeneticFastq",
  method: POST,
};
//risk
const ALLELICA_RISK_CALC = {
  url: BASIC_REST + "/riskCalculator",
  method: GET,
};

const GET_VARIANT_LIST = {
  url: VARIANTS_REST + "/getVariantList",
  method: POST,
};

const GET_VARIANT_LIST_CNV = {
  url: VARIANTS_REST + "/getVariantListCNV",
  method: POST,
};

const GET_VARIANT_LIST_SNV = {
  url: VARIANTS_REST + "/getVariantListSNV",
  method: POST,
};

const GET_VARIANT_CSV = {
  url: VARIANTS_REST + "/getVariantListCSV",
  method: POST,
};

const CHECK_VARIANT = {
  url: VARIANTS_REST + "/checkVariant",
  method: POST,
};

const CHANGE_PATHOGENIC_VARIANT = {
  url: VARIANTS_REST + "/changePathogenicVariant",
  method: POST,
};

const CHANGE_PATHOGENIC_VARIANT_FOREVER = {
  url: VARIANTS_REST + "/changePathogenicVariantForever",
  method: POST,
};

const CHANGE_VARIANT_NAME_FOREVER = {
  url: VARIANTS_REST + "/changeVariantNameForever",
  method: POST,
};

const GET_VARIANT_FILTERS = {
  url: VARIANTS_REST + "/getVariantFilters",
  method: GET,
};
const SAVE_VARIANT_FILTER = {
  url: VARIANTS_REST + "/saveVariantFilter",
  method: POST,
};
const DELETE_VARIANT_FILTER = {
  url: VARIANTS_REST + "/deleteVariantFilter",
  method: POST,
};

const SAVE_VARIANT_SAMPLE_DEFAULT_FILTER = {
  url: VARIANTS_REST + "/saveVariantDefaultFilter",
  method: POST,
};

const SAVE_X_FRAGILE = {
  url: VARIANTS_REST + "/saveXFragile",
  method: POST,
};

const SAVE_ADDITIONA_GENE_INFO = {
  url: VARIANTS_REST + "/saveAdditionalGeneInfo",
  method: POST,
};

const ADD_VARIANT_COMMENT = {
  url: VARIANTS_REST + "/addVariantComment",
  method: POST,
};

const GET_VARIANT_COMMENTS = {
  url: VARIANTS_REST + "/getVariantComments",
  method: GET,
};

const GET_TEST_COMMENTS = {
  url: BASIC_REST + "/getTestComments",
  method: GET,
};

const ADD_TEST_COMMENT = {
  url: BASIC_REST + "/addTestComment",
  method: POST,
};

const GET_RESULT_STATISTICS = {
  url: BASIC_REST + "/getResultsStatistics",
  method: GET,
};

/*
 *
 *      THIS IS THE PRISM PROJECT
 *
 *
 */
const PRISM_REST = BASIC_REST + "/PRISM_PROJECT";

const SEARCH_PRISM_LIST = {
  url: PRISM_REST + "/allProteinsPhenoControlled",
  method: POST,
};

const GET_PRISM_PROTEIN_ANNOTATIONS = {
  url: PRISM_REST + "/getProteinAnnotations",
  method: GET,
};
const GET_PRISM_PROTEIN_FUNCTIONS = {
  url: PRISM_REST + "/getProteinFunctions",
  method: GET,
};
const GET_PRISM_CSV = {
  url: PRISM_REST + "/getCSV",
  method: POST,
};

const RUN_LOCAL_DOCKER_IMAGE = {
  url: BASIC_REST + "/runLocalTest",
  method: POST,
};
const RUN_LOCAL_PATIENT_DOCKER_IMAGE = {
  url: BASIC_REST + "/runLocalPatientTest",
  method: POST,
};

const GENERATE_LOCAL_REPORT = {
  url: BASIC_REST + "/generateLocalReport",
  method: POST,
};

const GET_CARRIER_SCREENING_FILE = {
  url: BASIC_REST + "/getCarrierScreeningFile",
  method: GET,
};
const SAVE_CARRIER_SCREENING_FILE = {
  url: BASIC_REST + "/saveCarrierScreeningFile",
  method: POST,
};

const UPLOAD_REIMBURSEMENT_FILES = {
  url: BASIC_REST + "/upload_reimbursement_files",
  method: POST,
};

export {
  REGISTER_NEW_TEST,
  MODIFY_PRS_LIST_TEST,
  UPDATE_PATIENT_INFO,
  CREATE_PAYMENT_INTENT,
  SEARCH_REGISTERED_TEST,
  SEARCH_REGISTERED_TEST_VARIANTS,
  PATIENT_LIST_VIEW,
  REGISTER_NEW_TEST_CSV,
  UPLOAD_PATIENT_CONSENT,
  GET_PATIENT_CONSENT,
  GET_PATIENT_REPORT,
  GET_TRACKING_INFO,
  SEARCH_PATIENT_INFO,
  PATIENT_DETAILS,
  GET_USER_INFO,
  SAVE_USER_CONFIG,
  CHANGE_USER_PASSWORD,
  ASSIGN_BARCODE_PATIENT,
  DEALLOCATE_BARCODE_PATIENT,
  DELETE_PATIENT_CONSENT,
  UPLOAD_GENETIC_IDAT,
  DELETE_GENETIC_IDAT,
  UPLOAD_GENETIC_VCF,
  DELETE_GENETIC_VCF,
  UPLOAD_GENETIC_CEL,
  DELETE_GENETIC_CEL,
  UPLOAD_GENETIC_FASTQ,
  DELETE_GENETIC_FASTQ,
  ALLELICA_RISK_CALC,
  SEARCH_PRISM_LIST,
  GET_PRISM_PROTEIN_ANNOTATIONS,
  GET_PRISM_PROTEIN_FUNCTIONS,
  GET_PRISM_CSV,
  GET_VARIANT_LIST,
  GET_VARIANT_CSV,
  GET_VARIANT_LIST_CNV,
  GET_VARIANT_LIST_SNV,
  GET_VARIANT_FILTERS,
  SAVE_X_FRAGILE,
  SAVE_ADDITIONA_GENE_INFO,
  GET_VARIANT_COMMENTS,
  ADD_VARIANT_COMMENT,
  SAVE_VARIANT_FILTER,
  DELETE_VARIANT_FILTER,
  CHANGE_PATHOGENIC_VARIANT,
  CHANGE_PATHOGENIC_VARIANT_FOREVER,
  CHANGE_VARIANT_NAME_FOREVER,
  CHECK_VARIANT,
  SAVE_VARIANT_SAMPLE_DEFAULT_FILTER,
  RUN_LOCAL_DOCKER_IMAGE,
  RUN_LOCAL_PATIENT_DOCKER_IMAGE,
  GENERATE_LOCAL_REPORT,
  SEARCH_JOINT_REPORTS,
  DOWNLOAD_JOINT_REPORT,
  GET_TEST_COMMENTS,
  ADD_TEST_COMMENT,
  GET_RESULT_STATISTICS,
  GET_CARRIER_SCREENING_FILE,
  SAVE_CARRIER_SCREENING_FILE,
  UPLOAD_REIMBURSEMENT_FILES,
};
